<template>
	<div class="modify-details">
		<el-breadcrumb separator-class="el-icon-arrow-right">
			<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
			<el-breadcrumb-item>价格修改明细</el-breadcrumb-item>
		</el-breadcrumb>
		<el-table :data="tableData" class="main-table" header-row-class-name="table-header"
			cell-class-name="table-hover" :row-class-name="tableRowClassName" ref="table" highlight-current-row
			>
			<el-table-column label="序号" type="index" :index="indexMethod" width="80" align="center"></el-table-column>
			<el-table-column prop="time" label="修改时间"></el-table-column>
			<el-table-column prop="name" label="备品名称"></el-table-column>
			<el-table-column prop="code" label="原厂零件号"></el-table-column>
			<el-table-column prop="priceEndUser" label="指导价" align="center">
				<template slot-scope="scope">
					<span :class="{'modified':scope.row.price1Modified}">{{scope.row.price1}}</span>
				</template>
			</el-table-column>
			<el-table-column prop="priceEndUser" label="服务站价" align="center">
				<template slot-scope="scope">
					<span :class="{'modified':scope.row.price2Modified}">{{scope.row.price2}}</span>
				</template>
			</el-table-column>
			<el-table-column prop="priceEndUser" label="精品件价格" align="center">
				<template slot-scope="scope">
					<span :class="{'modified':scope.row.price3Modified}">{{scope.row.price3}}</span>
				</template>
			</el-table-column>
			<el-table-column prop="priceEndUser" label="副厂件价格" align="center">
				<template slot-scope="scope">
					<span :class="{'modified':scope.row.price4Modified}">{{scope.row.price4}}</span>
				</template>
			</el-table-column>
			<el-table-column prop="priceEndUser" label="原厂供货价" align="center">
				<template slot-scope="scope">
					<span :class="{'modified':scope.row.price5Modified}">{{scope.row.price5}}</span>
				</template>
			</el-table-column>
		</el-table>
		<el-pagination background layout="prev, pager, next" :total="this.total" :current-page.sync="query.page"
			:page-size="query.pagesize" @current-change="getList" v-if="tableShow">
		</el-pagination>
	</div>
</template>

<script>
    import { fetchList } from "../../api/insurePrice";

    export default {
		data() {
			return {
				query: {
					page: 1,
					pagesize: 10,
					keyword: "",
					sort: "-id",
                    total: "",
				},
				tableData: []
			}
		},
        created() {
            this.getList();
        },
        methods: {
            getList() {
                fetchList(this.query).then(res => {
                    this.tableData = res.data.list;
                    this.total = res.data.total;
                });
            },
            indexMethod(index) {
                return index + 1;
            },
			tableRowClassName({
				rowIndex
			}) {
				if (rowIndex % 2 == 0) {
					return "";
				} else {
					return "active-row";
				}
			},
		}
	}
</script>

<style lang="scss" scoped>
	.modified {
		color: $main-light-color;
	}
</style>
